import React from 'react';

import bond from '../images/team/bond.png';
import matt from '../images/team/matt.png';
import roux from '../images/team/steve-leroux.png';
import Layout from '../components/Layout';
import MetaData from '../components/meta/MetaData';
import SignUp from '../components/website/SignUp';
import Phone from '../components/website/Phone';
import { Header, BodyBold, Body, ArticleWrapper, ArticleImg, Wrapper, ArticleBody } from '../styles';

const Press = () => (
	<Layout>
		<MetaData
			title="Envel, a U.S. Digital Banking Challenger, goes live in App stores."
			description="Envel, a U.S. Digital Banking Challenger, goes live in App stores."
			image="images/social/social.png"
		/>
		<Header center>Envel, a U.S. Digital Banking Challenger, goes live in App stores.</Header>
		<ArticleBody>
			<Body>
				<BodyBold>Boston, MA / ACCESWIRE / December 4, 2020</BodyBold>
				<br />
				Envel, the Harvard Founded, MIT Sandbox Backed Fintech today announced that it is now live in the U.S. Apple App
				Store and Google Play Store as one of the world’s first Autonomous Bank accounts[1], using AI and behavioral
				science to automate elements of people’s financial management; like budgeting, savings and investments that they
				don’t have the time, skills or discipline to do themselves.
			</Body>
			<Body>
				Following six months of successful live public trials, and partnering with Q2, nbkc Bank, StoneCastle, Plaid,
				Apple Pay, Google Pay, Jumio, Q2 BaaS, Visa DPS and AWS. Founder and CEO Steve Le Roux, said,
			</Body>
			<ArticleWrapper row justifyCenter alignCenter>
				<ArticleImg src={roux} />
				<Body>
					&quot;I am very proud that our dream of delivering true Autonomous Banking to help millions of people to
					improve their financial fitness, is now live and becoming a reality. We are most grateful to our lead
					investor, Paul Pagnato and all our Angel investors who have helped us get to this point by providing Envel
					with the insights and funding needed to begin building the World’s Smartest Bank Account.&quot; [1]
				</Body>
			</ArticleWrapper>
			<Body>
				Using patent-pending technologies, Envel has adopted a unique design philosophy to deliver its vision to
				automate every element of banking, payments, investing and wealth management which people find too difficult or
				too time consuming. Envel is designed to service a new generation of young users as well as the tens of millions
				of people that in a post-COVID world will be living pay-check to pay-check, by helping them better manage their
				money, avoid unnecessary debt and create real savings and wealth through Envel’s unique ‘Autopilot’ function
				which early users have labelled ‘Driverless Banking’.
			</Body>
			<Body>Envel Chairman and President, Craig Bond, a former CEO of large consumer and business banks, said,</Body>
			<ArticleWrapper row justifyCenter alignCenter>
				<ArticleImg src={bond} />
				<Body>
					&quot;I was drawn to Envel’s vision of automating banking and wealth creation to make it simpler, better and
					more helpful. I joined the team to help them deliver this dream using new technologies, behavioral science
					insights, data and AI, something that the world’s big banks are struggling with and are not yet able to
					deliver. Envel will continue to hire the best global talent and use cutting edge technologies, but the
					foundation of how we differ from other Banks and Fintechs will be our focus on automation, automation,
					automation and our unique approach to mobile design and user experience in order to meet the needs of Envel’s
					community of users.&quot;
				</Body>
			</ArticleWrapper>
			<Body>Envel CFO, Matt Armandi confirmed that,</Body>
			<ArticleWrapper row justifyCenter alignCenter>
				<ArticleImg src={matt} />
				<Body>
					&quot;At the same time that we are launching in the U.S., we also want to democratize banking by allowing our
					early users an opportunity to become investors and early ‘Owners’ of Envel, so that they can benefit from our
					future success. On Dec 4 we launched a crowd funding round on the Wefunder platform (www.wefunder.com/envel)
					to attract a new class of investors. The first confirmed investors in this round will be Nick Ogden, the
					inventor of e-commerce and founder of WorldPay, as well as Global Millennial Capital, Praesidium Capital and a
					number of Envel staff, friends and family.&quot;
				</Body>
			</ArticleWrapper>
			<br />
			<Wrapper alignCenter>
				<Phone />
			</Wrapper>
			<Body>
				<BodyBold>About Envel, Inc.</BodyBold>
			</Body>
			<Body>
				Envel is an U.S. financial technology company, providing customers with the ability to open up to 99 FDIC
				insured[1] bank accounts within seconds powered by its envelope system. Envel provides users with a contactless
				Envel Visa® Debit Card[1] as well as access to Apple Pay and Google Pay. Envel enables a fully autonomous
				experience with budgeting and savings on Autopilot. The Envel app claims to be digital banking&apos;s most
				automated experience and has been designed to use artificial intelligence and draw upon behavioral science
				insights to take the anxiety out of managing finances.
			</Body>
			<Body>
				Headquartered in Boston, Massachusetts, Envel has strong connections to Harvard and MIT, serving as the title
				sponsor of both HackHarvard 2019 and the MIT Fintech conference 2020. Envel is also a member of DCU FinTech
				Innovation Lab and a start-up member of MIT CSAIL (Computer Science and Artificial Intelligence Lab).
			</Body>
			<Body>
				The Envel team is led by founder and CEO Steve Le Roux, who came up with the idea for Envel in 2016 while
				studying at Harvard. Together with Co-founder Diederik Meeuwis, the Envel Team includes talented individuals who
				have worked at Paypal, Barclays, Santander, Wells Fargo, JP Morgan & Chase, and Circle. Craig Bond serves as
				Envel&apos;s Chairman and President. He is a former CEO of Retail and Business Banking at Barclays Africa Group
				and Absa Bank, former CEO of Standard Bank Africa and Standard Bank China and American Express SA, as well as
				being former Chairman of MasterCard (South Asia Middle East Africa Board), Woolworths Financial Services(Pty)Ltd
				and Ford Financial Services (Pty)Ltd.
			</Body>
			<Body>
				[1] FDIC insurance is provided by nbkc bank, Member FDIC. The Envel Visa® Debit Card is issued by nbkc bank
				pursuant to a license from Visa U.S.A. Inc.
			</Body>
			<Body>
				<BodyBold>MEDIA CONTACT</BodyBold>
				<br />
				Jacqueline Epstein
				<br />
				<a href="mailto:pr-contact@envel.ai" target="_blank" rel="noopener noreferrer">
					pr-contact@envel.ai
				</a>
			</Body>
			{/* <Body>
					View source version on accesswire.com:
					<br />
					<a
						target="_blank"
						rel="noopener noreferrer"
						href="https://www.accesswire.com/606535/US-Banking-Challenger-Envel-Raises-27M-Seed-to-launch-first-Autonomous-Bank-Account"
					>
						https://www.accesswire.com/606535/US-Banking-Challenger-Envel-Raises-27M-Seed-to-launch-first-Autonomous-Bank-Account
					</a>
				</Body> */}
		</ArticleBody>
		<SignUp />
	</Layout>
);

export default Press;
